import { produce } from 'immer'
import { isEmpty, merge } from 'lodash/fp'
import { type ActionType, getType } from 'typesafe-actions'

import { fetchCleanByIdAction } from '../cleans/actions'
import { fetchTicketByIdAction } from '../tickets/actions'
import { fetchUnitByIdAction } from './actions/fetchUnitById'
import { fetchUnitsAction } from './actions/fetchUnits'
import { updateSelectedUnitAction } from './actions/updateSelectedUnit'
import {
  type UnitsState,
  type NormalizedUnitsApiResponse,
  type UnitAttributes,
  type UnitRelationships,
} from './units.types'
import { emptyNormalizedUnitsData } from './units.utils'
import { type JSONApiObjectWithRelationships } from 'packages/utils/store'

export const initialState: UnitsState = {
  data: {},
  selectedUnitId: '',
}

const actions = {
  fetchCleanByIdAction,
  fetchTicketByIdAction,
  fetchUnitByIdAction,
  fetchUnitsAction,
  updateSelectedUnitAction,
}

type Actions = ActionType<typeof actions>

const SELECTED_UNIT_ID_KEY = 'selectedUnitId'

type UnitType = JSONApiObjectWithRelationships<
  UnitAttributes,
  UnitRelationships
>

export const isUnitsResponse = (
  response: unknown,
): response is NormalizedUnitsApiResponse => {
  return !!(response as NormalizedUnitsApiResponse)?.normalized
}

export const mergeUnits = (
  draft: UnitsState,
  state: UnitsState,
  normalized: NormalizedUnitsApiResponse,
) => {
  const units = normalized.normalized?.unit || {}
  Object.values(units).forEach((incomingUnit: UnitType) => {
    const existingUnit = state.data[incomingUnit.id] || {}
    const mergedUnit = merge(existingUnit, incomingUnit)
    draft.data[incomingUnit.id] = mergedUnit
  })
}

export const getValidSelectedUnitId = (
  state: UnitsState,
  normalized: NormalizedUnitsApiResponse,
): string => {
  const cachedSelectedUnitId = localStorage.getItem(SELECTED_UNIT_ID_KEY) || ''
  const normalizedUnits = normalized.normalized?.unit || {}

  const isValidUnit =
    !!cachedSelectedUnitId &&
    (!!state.data[cachedSelectedUnitId] ||
      !!normalizedUnits[cachedSelectedUnitId])

  if (isValidUnit) {
    return cachedSelectedUnitId
  }

  // If we have an invalid unit, just grab the first one from the data
  if (!isEmpty(normalizedUnits)) {
    const firstUnit = Object.values(normalizedUnits)[0] as UnitType | undefined
    return firstUnit?.id || ''
  }

  return ''
}

export const handleUnitsSuccess = (
  draft: UnitsState,
  state: UnitsState,
  normalized: NormalizedUnitsApiResponse,
) => {
  // Edge case: for users with no units, there is nothing to do here except clear localStorage
  if (!normalized.normalized?.unit) {
    localStorage.removeItem(SELECTED_UNIT_ID_KEY)
    return
  }

  mergeUnits(draft, state, normalized)

  // Only set selectedUnitId and localStorage if it's not already set
  if (!state.selectedUnitId) {
    const selectedUnitId = getValidSelectedUnitId(state, normalized)
    draft.selectedUnitId = selectedUnitId
    localStorage.setItem(SELECTED_UNIT_ID_KEY, selectedUnitId)
  }
}

const handleUpdateSelectedUnit = (
  draft: UnitsState,
  selectedUnitId: string,
) => {
  localStorage.setItem(SELECTED_UNIT_ID_KEY, selectedUnitId)
  draft.selectedUnitId = selectedUnitId
}

export const unitsReducer = (
  state = initialState,
  action: Actions,
): UnitsState =>
  produce(state, (draft: UnitsState) => {
    switch (action.type) {
      // we are purposefully ignoring fetchCleansAction and fetchTicketsAction to avoid race conditions that cause invalid data
      case getType(fetchTicketByIdAction.success):
      case getType(fetchCleanByIdAction.success):
      case getType(fetchUnitByIdAction.success):
      case getType(fetchUnitsAction.success): {
        const normalized = action.payload || emptyNormalizedUnitsData
        if (isUnitsResponse(normalized)) {
          handleUnitsSuccess(draft, state, normalized)
        }
        return
      }

      case getType(updateSelectedUnitAction): {
        handleUpdateSelectedUnit(draft, action?.payload || '')
      }
    }
  })
