import { produce } from 'immer'
import get from 'lodash/get' // eslint-disable-line
import { type ActionType, getType } from 'typesafe-actions'

import { setAuthUser } from 'packages/utils/misc'
import { setLoggingUser } from 'packages/wiretap/logging'
import { type JSONApiObjectWithRelationships } from 'packages/utils/store'
import { type NormalizedCleansApiResponse } from '../cleans/cleans.types'
import { emptyCleansResponse } from '../cleans/cleans.utils'
import { type NormalizedSmartLocksApiResponse } from '../smartLocks/smartLocks.types'
import { emptySmartLocksResponse } from '../smartLocks/smartLocks.utils'

import {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchPreviousCleansAction,
} from 'app/store/cleans/actions'

import { fetchSmartLocksByUnitIdAction } from '../smartLocks/actions'
import { fetchTicketByIdAction } from '../tickets/actions'
import { fetchUnitByIdAction } from '../units/actions'
import {
  fetchCurrentUserAction,
  searchUsersAction,
  setActiveUserAction,
  setMayImpersonateUserAction,
} from './actions'
import {
  type RawUser,
  type UsersState,
  type UserAttributes,
  type UserRelationships,
  type NormalizedUsersApiResponse,
  type User,
} from './users.types'
import { emptyUsersResponse } from './users.utils'

const initialState: UsersState = {
  activeUserId: '',
  authUserId: '',
  data: {},
  mayImpersonate: false,
  searchResults: {},
}

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchPreviousCleansAction,
  fetchCurrentUserAction,
  fetchSmartLocksByUnitIdAction,
  fetchTicketByIdAction,
  fetchUnitByIdAction,
  searchUsersAction,
  setActiveUserAction,
  setMayImpersonateUserAction,
}

type UsersActionsTypes = ActionType<typeof actions>
export type UserType = JSONApiObjectWithRelationships<
  UserAttributes,
  UserRelationships
>

export const handleCleansSuccess = (
  draft: UsersState,
  state: UsersState,
  normalized: NormalizedCleansApiResponse,
) => {
  draft.data = {
    ...state.data,
    ...normalized.normalized?.user,
  }
}

export const updateDataLayer = (userId: string) => {
  // add the authenticated user ID to Google Tag Manager's variables
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataLayer = (window as any).dataLayer
  if (dataLayer) {
    dataLayer.push({ userId })
  }
}

export const handleCurrentUserSuccess = (
  draft: UsersState,
  normalized: NormalizedUsersApiResponse,
) => {
  const users = normalized.normalized?.user || {}
  const authUser = Object.values(users)[0] as UserType

  if (!authUser) return

  draft.activeUserId = authUser.id
  draft.authUserId = authUser.id
  draft.data = users

  setAuthUser(authUser)
  setLoggingUser(draft.authUserId, draft.activeUserId)

  if (authUser.attributes.isSuperuser) {
    // do not set false since that's already the default and the value
    // could be true based on an IDP scope, which shouldn't be overridden
    draft.mayImpersonate = true
  }

  updateDataLayer(authUser.id)
}

export const handleSmartLocksSuccess = (
  draft: UsersState,
  normalized: NormalizedSmartLocksApiResponse,
) => {
  const users = normalized.normalized?.user || {}
  Object.values(users).forEach(user => {
    const typedUser = user as UserType
    draft.data[typedUser.id] = typedUser
  })
}

export const handleSearchSuccess = (
  draft: UsersState,
  normalized: NormalizedUsersApiResponse,
) => {
  draft.searchResults = normalized.normalized?.user || {}
}

export const handleSetActiveUser = (
  draft: UsersState,
  state: UsersState,
  user: User,
) => {
  draft.activeUserId = user.id
  setLoggingUser(state.authUserId, draft.activeUserId)

  // if this is a user we have selected from a search, we need to copy
  // said user over to "data" so it does not get cleared on the next search
  const userFromSearch = state.searchResults[user.id]
  if (!state.data[user.id] && userFromSearch) {
    draft.data[user.id] = userFromSearch
  }
}

export const usersReducer = (
  state = initialState,
  action: UsersActionsTypes,
): UsersState =>
  produce(state, (draft: UsersState) => {
    switch (action.type) {
      // save users included with HK data when fetching cleans
      case getType(fetchCleanByIdAction.success):
      case getType(fetchPreviousCleansAction.success):
      case getType(fetchCleansAction.success): {
        const normalized = action.payload || emptyCleansResponse
        handleCleansSuccess(draft, state, normalized)
        return
      }

      case getType(fetchCurrentUserAction.success): {
        const normalized = action.payload || emptyUsersResponse
        handleCurrentUserSuccess(draft, normalized)
        return
      }
      // users included with smartLocks (as "generated_by")
      case getType(fetchSmartLocksByUnitIdAction.success): {
        const normalized = action.payload || emptySmartLocksResponse
        handleSmartLocksSuccess(draft, normalized)
        return
      }

      case getType(searchUsersAction.success): {
        const normalized = action.payload || emptyUsersResponse
        handleSearchSuccess(draft, normalized)
        return
      }

      case getType(setActiveUserAction): {
        handleSetActiveUser(draft, state, action.payload)
        return
      }

      case getType(setMayImpersonateUserAction): {
        draft.mayImpersonate = action.payload
      }
    }
  })
