import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Loader } from 'packages/common'
import { IconName } from 'packages/iconic'

import { useI18n, Slugs } from 'app/i18n'
import { type GwLocalHost } from 'app/store/gwLocalhosts'
import { type GwUnitStaffer } from 'app/store/gwUnitStaffers'

import { UnitSelectButton } from '../../core'
import { useUnitPickerModal } from '../../core/hooks/useUnitPickerModal/useUnitPickerModal'
import { NotificationFormContainer } from '../NotificationForm/NotificationForm.container'
import {
  ContactInfoIcon,
  ContactInfoIconBackground,
} from '../SupportPage/SupportPage.styles'
import { AccessSection } from './AccessSection'
import { getStafferByType } from './MyUnitsPage.helpers'
import * as St from './MyUnitsPage.styles'

export enum MyUnitsPageTestIds {
  container = 'MyUnitsPage__container',
}

export interface MyUnitsPageProps {
  gwLocalHosts: GwLocalHost[]
  gwUnitStaffers: GwUnitStaffer[]
  loading: boolean
  onDelete: () => void
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    access: t(Slugs.access),
    accessBody: t(Slugs.accessBody),
    addAccess: t(Slugs.addAccess),
    addAccessBody: t(Slugs.addAccessBody),
    localHost: t(Slugs.localHost),
    myHomes: t(Slugs.myHomes),
    noneAdded: t(Slugs.noneAdded),
    notifications: t(Slugs.notifications),
    notificationsBody: t(Slugs.notificationsBody),
    noUnitAvailable: t(Slugs.noUnitAvailable),
    primaryHousekeeper: t(Slugs.primaryHousekeeper),
    primaryHousekeeperBody: t(Slugs.primaryHousekeeperBody),
  }
}

interface ContactInfoProps {
  icon: IconName
  value?: string
  noneAddedText: string
}

const ContactInfo: React.FC<ContactInfoProps> = ({
  icon,
  value,
  noneAddedText,
}) => (
  <St.ContactInfoContainer>
    <ContactInfoIconBackground>
      <ContactInfoIcon icon={icon} size={16} />
    </ContactInfoIconBackground>
    <St.ContactInfo>{value || noneAddedText}</St.ContactInfo>
  </St.ContactInfoContainer>
)

interface StafferSectionProps {
  title: string
  description: string
  staffer: GwUnitStaffer | undefined
  isEditing: boolean
  onEditToggle: () => void
  onEditSuccess: () => void
  onEditCancel: () => void
  stafferType: 'cx_contact' | 'primary_housekeeper'
  showDelete?: boolean
  onDelete?: () => void
  noneAddedText: string
  loading?: boolean
}

const StafferSection: React.FC<StafferSectionProps> = ({
  title,
  description,
  staffer,
  isEditing,
  onEditToggle,
  onEditSuccess,
  onEditCancel,
  stafferType,
  showDelete,
  onDelete,
  noneAddedText,
  loading,
}) => {
  if (isEditing) {
    return (
      <NotificationFormContainer
        gwUnitStaffer={staffer}
        onCancel={onEditCancel}
        onSuccess={onEditSuccess}
        stafferType={stafferType}
      />
    )
  }

  return (
    <>
      <St.StafferNameHeader>
        <St.Name>{staffer?.name || noneAddedText}</St.Name>
        <St.StafferEditOptions>
          <St.IconButton onClick={onEditToggle}>
            <St.IconBackground>
              <St.Icon icon={IconName.edit} size={16} />
            </St.IconBackground>
          </St.IconButton>
          {showDelete && (
            <St.IconButton onClick={onDelete} disabled={!staffer}>
              <St.IconBackground>
                <St.DeleteIcon
                  isDisabled={!staffer}
                  icon={IconName.trash}
                  size={18}
                />
              </St.IconBackground>
            </St.IconButton>
          )}
        </St.StafferEditOptions>
      </St.StafferNameHeader>

      <ContactInfo
        icon={IconName.phone}
        value={staffer?.phone}
        noneAddedText={noneAddedText}
      />
      <ContactInfo
        icon={IconName.mail}
        value={staffer?.email}
        noneAddedText={noneAddedText}
      />
      {loading && <Loader />}
    </>
  )
}

export const MyUnitsPage: React.FC<MyUnitsPageProps> = React.memo(
  ({ gwUnitStaffers, gwLocalHosts, loading, onDelete }) => {
    const navigate = useNavigate()
    const strings = useTranslations()

    const primaryHousekeeperStaffer = getStafferByType(
      gwUnitStaffers,
      'primary_housekeeper',
    )
    const localHostStaffer = getStafferByType(gwUnitStaffers, 'cx_contact')

    const [isEditingPrimaryHousekeeper, setIsEditingPrimaryHousekeeper] =
      React.useState(false)
    const [isEditingCxContact, setIsEditingCxContact] = React.useState(false)

    const { selectedUnit, showUnitPickerModal } = useUnitPickerModal()

    return (
      <section data-testid={MyUnitsPageTestIds.container}>
        <St.HeadingContainer>
          <St.HeadingIconContainer onClick={() => navigate('/settings')}>
            <St.HeadingIcon icon={IconName.leftArrow} size={16} />
          </St.HeadingIconContainer>
          <St.Heading>{strings.myHomes}</St.Heading>
        </St.HeadingContainer>

        <St.ButtonWrapper>
          <UnitSelectButton onPress={showUnitPickerModal}>
            {selectedUnit?.name}
          </UnitSelectButton>
        </St.ButtonWrapper>

        {selectedUnit ? (
          <>
            <AccessSection gwLocalHosts={gwLocalHosts} loading={loading} />
            <St.Divider />

            <St.NotificationsSection>
              <St.SectionHeading id="notifications">
                {strings.notifications}
              </St.SectionHeading>

              <St.SectionSubHeading>{strings.localHost}</St.SectionSubHeading>
              <St.SectionBody>{strings.notificationsBody}</St.SectionBody>

              <StafferSection
                title={strings.localHost}
                description={strings.notificationsBody}
                staffer={localHostStaffer}
                isEditing={isEditingCxContact}
                onEditToggle={() => setIsEditingCxContact(prev => !prev)}
                onEditSuccess={() => setIsEditingCxContact(false)}
                onEditCancel={() => setIsEditingCxContact(false)}
                stafferType="cx_contact"
                noneAddedText={strings.noneAdded}
              />

              <St.SectionSubHeading>
                {strings.primaryHousekeeper}
              </St.SectionSubHeading>

              <St.SectionBody>{strings.primaryHousekeeperBody}</St.SectionBody>

              <St.PrimaryHkSection>
                <StafferSection
                  title={strings.primaryHousekeeper}
                  description={strings.primaryHousekeeperBody}
                  staffer={primaryHousekeeperStaffer}
                  isEditing={isEditingPrimaryHousekeeper}
                  onEditToggle={() =>
                    setIsEditingPrimaryHousekeeper(prev => !prev)
                  }
                  onEditSuccess={() => setIsEditingPrimaryHousekeeper(false)}
                  onEditCancel={() => setIsEditingPrimaryHousekeeper(false)}
                  stafferType="primary_housekeeper"
                  showDelete
                  onDelete={onDelete}
                  noneAddedText={strings.noneAdded}
                  loading={loading}
                />
              </St.PrimaryHkSection>
            </St.NotificationsSection>
          </>
        ) : (
          <St.NoUnitAlert alertType={'info'}>
            <div>{strings.noUnitAvailable}</div>
          </St.NoUnitAlert>
        )}
      </section>
    )
  },
)
