import { produce } from 'immer'
import { type ActionType, getType } from 'typesafe-actions'

import {
  getIdAndDataTypeFromAction,
  type JSONApiObjectWithRelationships,
  type NormalizedJSONApiResponse,
} from 'packages/utils/store'

import { getLastFetchTimestamp } from 'app/utils/cacheHelpers'

import {
  fetchTicketsAction,
  fetchTicketByIdAction,
  fetchTicketingMaintenanceDispositionsAction,
  updateTicketAction,
} from './actions'
import {
  type RawTicket,
  type TicketAttributes,
  type TicketRelationships,
  type TicketsState,
  type TicketsResponse,
  type DispositionResponse,
} from './tickets.types'

const actions = {
  fetchTicketByIdAction,
  fetchTicketingMaintenanceDispositionsAction,
  fetchTicketsAction,
  updateTicketAction,
}

type TicketsActionsTypes = ActionType<typeof actions>

export const initialState: TicketsState = {
  data: {},
  dispositions: {},
  lastFetch: 0,
  loading: false,
}

export const handleRequestAction = (draft: TicketsState) => {
  draft.loading = true
}

export const handleTicketsSuccess = (
  draft: TicketsState,
  normalized: NormalizedJSONApiResponse<TicketsResponse>,
) => {
  draft.data = normalized.normalized?.ticket || {}
  draft.lastFetch = getLastFetchTimestamp('tickets')
  draft.loading = false
}

export const handleTicketByIdSuccess = (
  draft: TicketsState,
  action: ReturnType<typeof actions.fetchTicketByIdAction.success>,
) => {
  const [id, ticket] = getIdAndDataTypeFromAction<RawTicket>(action, 'ticket')
  draft.data[id] = ticket as JSONApiObjectWithRelationships<
    TicketAttributes,
    TicketRelationships
  >
}

export const handleDispositionsSuccess = (
  draft: TicketsState,
  normalized: NormalizedJSONApiResponse<DispositionResponse>,
) => {
  draft.dispositions = normalized.normalized?.disposition || {}
  draft.loading = false
}

export const handleUpdateTicketSuccess = (
  draft: TicketsState,
  normalized: NormalizedJSONApiResponse<TicketsResponse>,
) => {
  const data = normalized.normalized?.ticket || {}
  const tickets = Object.values(data)
  if (!tickets.length) return

  const ticket = tickets[0] as JSONApiObjectWithRelationships<
    TicketAttributes,
    TicketRelationships
  >
  if (!ticket.id || !draft.data[ticket.id]) return

  draft.data[ticket.id].attributes = {
    ...draft.data[ticket.id].attributes,
    ...ticket.attributes,
  }
}

export const ticketsReducer = (
  state = initialState,
  action: TicketsActionsTypes,
): TicketsState =>
  produce(state, (draft: TicketsState) => {
    switch (action.type) {
      case getType(fetchTicketsAction.request): {
        handleRequestAction(draft)
        return
      }

      case getType(fetchTicketsAction.success): {
        handleTicketsSuccess(draft, action.payload)
        return
      }

      case getType(fetchTicketByIdAction.success): {
        handleTicketByIdSuccess(draft, action)
        return
      }

      case getType(fetchTicketingMaintenanceDispositionsAction.success): {
        handleDispositionsSuccess(draft, action.payload)
        return
      }

      case getType(updateTicketAction.success): {
        handleUpdateTicketSuccess(draft, action.payload)
        return
      }

      case getType(fetchTicketsAction.failure): {
        draft.data = initialState.data
        draft.loading = false
      }
    }
  })
